import PropTypes from "prop-types"
import React from "react"

import "./sectionLayout.css"

const classNames = require("classnames")

const SectionLayout = ({ children, id, className, marginAuto }) => {
  return (
    <div
      id={id}
      className={classNames({
        pageSectionContainer: true,
        marginAuto: marginAuto,
        [className]: className,
      })}
    >
      {children}
    </div>
  )
}

SectionLayout.propTypes = {
  children: PropTypes.any.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  marginAuto: PropTypes.bool,
}

export default SectionLayout
