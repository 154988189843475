import React from "react"

import SectionLayout from "../components/sectionLayout"

const NotFoundPage = () => (
  <div>
    <div>
      <main role="main">
        <div className="center" style={{ marginBottom: 50, marginTop: 50}}>
          <a href="/" className="logo-img" alt="Benefits Data Trust">
            <img src="/logo-header-benefitsdatatrust.png" />
          </a>  
        </div>
        <SectionLayout marginAuto className="center">
          <div>
            <h1 className="font-color-blue900 mb-16 mt-80 large-headline">
              404
            </h1>
            <h2 className="font-color-blue900 mb-16">
              We're sorry! The page you are looking for could not be found.
            </h2>
          </div>
        </SectionLayout>
      </main>
    </div>
  </div>
)

export default NotFoundPage
